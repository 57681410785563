import React from "react";
import { graphql } from "gatsby";
import { Seo } from "../../components/seo";
import { EntriesQueryInterface } from "../../types/entries";
import { LayoutDefault } from "../../layouts/default";
import { EntryLists } from "../../components/entry-lists";
import { GridContainer } from "../../components/grid/content-blocks/container";
import { GridRow } from "../../components/grid/content-blocks/row";
import { BreadCrumbs } from "../../components/breadcrumbs";
import { BreadCrumbItemInterface } from "../../components/breadcrumbs/types";

export const TagSearchPage = (props: EntriesQueryInterface) => {
  const { data, pageContext } = props;
  const {
    craft: { entries },
  } = data;

  // const schema: SchemaInterface = {
  //   type: 'BlogPosting',
  //   dateModified: dateUpdated,
  //   datePublished: postDate,
  //   name: title,
  //   headline: title,
  //   author,
  //   uri,
  // }

  const breadCrumbsList: BreadCrumbItemInterface[] = [
    {
      label: "Tags",
      url: "/tags",
    },
  ];

  return (
    <LayoutDefault>
      <Seo title={pageContext?.title} />
      <GridContainer>
        <GridRow>
          <BreadCrumbs crumbs={breadCrumbsList}></BreadCrumbs>
          <h1>{pageContext?.title}</h1>
          <EntryLists
            entries={entries}
            imageThumb
            showSnippet
            leftAlignContent
          />
        </GridRow>
      </GridContainer>
    </LayoutDefault>
  );
};

export const query = graphql`
  query TagSearchPageQuery($id: [craft_QueryArgument]) {
    craft {
      entries(section: ["articles", "projects"], entryTags: $id) {
        ...entryArticleStories
        ...entryProjectShowcase
      }
    }
  }
`;

export default TagSearchPage;
